<template>
	<div class="content">
		<img src="../assets/tab-pic.jpg" class="banner" alt="" />
		<p>系统正在升级中！</p>
	</div>
</template>

<script>
	export default{
		name: 'tips',
		data(){
			return{
				
			}
		},
		methods:{
			
		}
	}
</script>

<style scoped>
	.content{
		text-align: center;
		color: aliceblue;
		font-size: 24px;
	}
	.content >p{
		height: 31px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
	}
	.banner {
	  width: 100%;
	  height: auto;
	}
</style>
